import React, { useLayoutEffect, useRef } from "react";
import { useEffect, useState } from "react";

import { askGenieMessageType } from "src/helpers/types/askGenie.types";

import 'src/components/askGenie/askgenie.scss'
import chatbotLogo from 'src/images/askgenie_logo.png';
import { genericConversationAPI } from "src/utils/api/genericConverstation.api";
import { getEmployeeAlias, getToken } from "src/auth/cognito-auth";
import { webSocketInitializer } from "src/utils/api/webSocketUnitializer.api";
import { isNotEmpty } from "src/utils/utils";

import MarkdownPreview from '@uiw/react-markdown-preview';
import Box from "@amzn/meridian/box"
import Row from "@amzn/meridian/row"
import Button from "@amzn/meridian/button"
import Icon from "@amzn/meridian/icon"
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert"
import sendSmallTokens from "@amzn/meridian-tokens/base/icon/send-small"
import copyTokens from '@amzn/meridian-tokens/base/icon/copy';
import Tooltip from "@amzn/meridian/tooltip"
import refresh from '@amzn/meridian-tokens/base/icon/refresh';
import Text from "@amzn/meridian/text"
import Column from "@amzn/meridian/column";

import { v4 as uuidv4 } from 'uuid';
import { historyApi } from "src/utils/api/historyConversation.api";

const AskGenieComponent = () => {

    var cognitoToken = getToken()
    var alias = getEmployeeAlias()
    const getImageUrl = () => `https://badgephotos.corp.amazon.com/?uid=${alias}`;
    const [askGenieUserId] = useState(`${alias}-MainChatbot`);
    const [sessionId, setSessionId] = useState(uuidv4);

    const [inputText, setInputText] = useState('');
    const [chatMessages, setChatMessages] = useState<askGenieMessageType[]>([{
        messageBy: "ai",
        message: "Hello! I'm Amazon AskGenie - a Generative AI chatbot that aims to provide faster responses to your questions about Amazon Transportation Services topics. How can I assist you today?",
        copyToClipboard: true
    },]);
    const [isWaitingResponse, setIsWaitingResponse] = useState(false);
    const [isUserResponseRecieved, setIsUserResponseRecieved] = useState(false);
    const [proxyMessage, setProxyMessage] = useState<askGenieMessageType>({});
    const [isWebSocketConnected, setIsWebSocketConnected] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const textAreaFormEndRef = useRef<HTMLTextAreaElement>(null);
    const wsRef = useRef<WebSocket | null>(null);
    const maxChars = 30000;
    const [isLoading, setLoading] = useState(false);
    const [history, setHistory] = useState({})

    useEffect(() => {
        const handleWebSocketMessage = (data: any) => {
            updateMessageInStreamingWay({
                messageBy: "ai",
                message: data,
                loading: false

            });

            setIsWebSocketConnected(true);
            if (textAreaFormEndRef.current) {
                setTimeout(() => {
                    textAreaFormEndRef.current!.focus();
                }, 0);
            }

        };
        const handleWebSocketError = (error: any) => {
            console.log("WebSocket error:", error);

            if (isNotEmpty(proxyMessage)) {
                setChatMessages([
                    ...chatMessages,
                    proxyMessage
                ]);
            }
            else {
                setChatMessages([...chatMessages
                ])
            }
            setIsWebSocketConnected(false)
            setIsWaitingResponse(false);
        };
        try {
            const webSocketURL = "wss://api-ws.askgenie.transportation.amazon.dev" + "/?userId=" + askGenieUserId + "&sessionId=" + sessionId + "&token=" + cognitoToken + "";
            wsRef.current = webSocketInitializer(handleWebSocketMessage, handleWebSocketError, webSocketURL);
            return () => {
                if (wsRef.current) {
                    wsRef.current.close()
                }

            };
        }
        catch (error) {
            handleWebSocketError(error);
        }
    }, []);

    useEffect(() => {
        scrollToBottom();
        // getChatHistory();
    }, [chatMessages]);

    const copyMessageToClipboard = (message: string) => {
        navigator.clipboard.writeText(message)
            .then(() => {
                console.log(`Copied "${message}" to clipboard`);
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    };

    const updateMessageInStreamingWay = (messageChunk: any) => {
        // WebSocket will sent this `last_message_from_ws` as  streaming end and this is last message
        if (messageChunk.message === "last_message_from_ws") {
            setChatMessages((prevMessages) => {
                const lastMessage = prevMessages.length > 0 ? prevMessages[prevMessages.length - 1] : null;
                if (lastMessage && lastMessage.messageBy === messageChunk.messageBy) {
                    const updatedMessage = {
                        ...lastMessage,
                        copyToClipboard: true,
                        loading: false
                    };
                    return [...prevMessages.slice(0, -1), updatedMessage];
                } else { return [...prevMessages] }
            })
            setIsWaitingResponse(false);
        }
        else {
            setChatMessages((prevMessages) => {
                const lastMessage = prevMessages.length > 0 ? prevMessages[prevMessages.length - 1] : null;
                if (lastMessage && lastMessage.messageBy === messageChunk.messageBy) {
                    // Update the last message
                    const updatedMessage = {
                        ...lastMessage,
                        message: lastMessage.message + messageChunk.message,
                        loading: false
                    };
                    return [...prevMessages.slice(0, -1), updatedMessage];
                } else {
                    // Add a new message
                    return [...prevMessages, messageChunk];

                }

            });
        }
    };

    const getAskGeniResponse = (userMessage: string) => {
        const newUserMessage = {
            messageBy: "human",
            message: userMessage
        }
        const body = {
            "session_id": sessionId,
            "user_query": userMessage,
            "user_alias": askGenieUserId
        }
        try {

            genericConversationAPI(body, cognitoToken).then((askGeniResponse) => {
                if (askGeniResponse.message) {
                    const newAskgenieMessage = {
                        messageBy: "ai",
                        message: askGeniResponse.message,
                        copyToClipboard: true,
                        loading: false

                    }
                    if (!isWebSocketConnected || isLastMessageLoading()) {
                        setChatMessages([
                            ...chatMessages,
                            newUserMessage,
                            newAskgenieMessage
                        ]);
                        setIsWaitingResponse(false)
                        if (textAreaFormEndRef.current) {
                            setTimeout(() => {
                                textAreaFormEndRef.current!.focus();
                            }, 0);
                        }
                    }
                    setProxyMessage(newAskgenieMessage);
                } else {
                    setChatMessages([
                        ...chatMessages,
                        newUserMessage,
                    ]);
                }
            })
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setInputText("");

        }
    }

    const handleUserInput = (userMessage: string) => {
        if (!userMessage.trim()) return;
        setIsWaitingResponse(true);
        const newUserMessage = {
            messageBy: "human",
            message: userMessage
        }
        const newBotMessage = {
            messageBy: "ai",
            message: "",
            loading: true
        }
        setChatMessages([
            ...chatMessages,
            newUserMessage,
            newBotMessage
        ]);

        setInputText("");
        setIsUserResponseRecieved(true);
        getAskGeniResponse(userMessage);
    }

    const isLastMessageLoading = () => {
        if (chatMessages.length === 0) return false;
        const lastMessage = chatMessages[chatMessages.length - 1];
        console.log("isLoading is ", isLoading)
        if ((isLoading || isWaitingResponse) && proxyMessage) {
            console.log('Fetching messages from API Gateway');
            return true;

        }

    }
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLFormElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleUserInput(inputText);
        }

    };

    function adjustHeight() {
        if (textAreaFormEndRef.current) {
            textAreaFormEndRef.current.style.height = "auto";
            textAreaFormEndRef.current.style.height = `${textAreaFormEndRef.current.scrollHeight}px`;
        }
    }

    useLayoutEffect(() => {
        if (!inputText && textAreaFormEndRef.current) {
            setTimeout(() => {
                if (textAreaFormEndRef.current) {
                    textAreaFormEndRef.current.style.height = "auto";

                }
            }, 300);
        }
        adjustHeight();
    }, [inputText]);
    function handleKeyDown(e: any) {
        adjustHeight();
    }
    const handleTextAreaOnchange = (event: any) => {
        setInputText(event.target.value)
        handleKeyDown(event)
    }

    // const getChatHistory = () => {
    //     const body = {
    //         user_alias: askGenieUserId
    //     }
    //     let formattedHistory = {}
    //     historyApi(body, cognitoToken,).then(async (askGeniHistoryResponse) => {
    //         // console.log("AskGenie  history api response is ", askGeniHistoryResponse)

    //         if (askGeniHistoryResponse.message && askGeniHistoryResponse.message[sessionId]) {
    //             formattedHistory = askGeniHistoryResponse.message[sessionId].map((msg: any) => ({
    //                 messageBy: msg.message_from,
    //                 message: msg.content.match(/<final_answe>(.*?)<\/final_answe>/) ? msg.content.match(/<final>(.*?)<\/final>/)[1] : msg.content,
    //                 copyToClipboard: true
    //             }));
    //             console.log("Cuurent history is ", formattedHistory)
    //             setHistory(formattedHistory);
    //         }
    //     })
    // }
    return (
        <React.Fragment>
            <Alert
                type="warning"
                size="large"
                title="Warning"
            >Our AskGenie is only trained for PEAK/CMBR and ATS OE meetings related questions as of now. </Alert>

            <div className="chatbot-container">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip position="start" title="New Chat" id="chat">
                        <Button type="icon" size="small" onClick={() => {
                            window.location.reload();
                        }}>
                            <Icon tokens={refresh}>New Session</Icon>
                            Clear Chat
                        </Button>
                    </Tooltip>
                </div>
                <div className="chatbot-box" >
                    {chatMessages.map((Chatmessage: any, index: any) => (
                        <div className="chatbot-messages" >
                            {Chatmessage.messageBy === 'human' ?
                                <Column className='user-message' key={index} alignmentHorizontal="end" spacing="none">
                                    <Row alignmentHorizontal="end" >
                                        <div key={index} >{Chatmessage.message}</div>

                                        <img src={getImageUrl()} alt="..." className="message-icon-human" />
                                    </Row>
                                    <Row alignmentHorizontal="end" >
                                        <Button
                                            type="icon"
                                            onClick={() => copyMessageToClipboard(String(Chatmessage.message))}
                                            size="small"
                                        >
                                            <Icon tokens={copyTokens} color="secondary" ></Icon>
                                        </Button>
                                    </Row>
                                </Column>
                                :
                                <Column key={index} className='bot-message' spacing="none">
                                    <Row>
                                        <img src={chatbotLogo} alt="..." className="message-icon-bot" />
                                        {Chatmessage.loading &&
                                            <Loader size="small" />}
                                        <div >
                                            <MarkdownPreview
                                                className="markdown-summary"
                                                source={Chatmessage.message!}
                                            />
                                        </div>
                                    </Row>
                                    <Row alignmentHorizontal="end" >
                                        {Chatmessage.copyToClipboard &&
                                            <Button
                                                type="icon"
                                                onClick={() => copyMessageToClipboard(String(Chatmessage.message))}
                                                size="small"
                                            >
                                                <Icon tokens={copyTokens} color="secondary" ></Icon>
                                            </Button>

                                        }
                                    </Row>
                                </Column>
                            }
                            <div ref={messagesEndRef} />
                        </div>
                    ))}


                </div>
                <Row className="askgenie-input-form" alignmentHorizontal="center" alignmentVertical="center" >
                    <form id="askgenie-form" className="askgenie-form">
                        <Row alignmentHorizontal="center" spacing="200">
                            <textarea
                                value={inputText}
                                onChange={handleTextAreaOnchange}
                                placeholder="Type your question..."
                                disabled={isWaitingResponse}
                                rows={2}
                                ref={textAreaFormEndRef}
                                className="scalable-textarea"
                                onKeyDown={handleKeyPress}
                                maxLength={maxChars}

                            />
                            <Button size="large" disabled={isWaitingResponse || !inputText.trim()} submit={true} type="secondary"
                                onClick={() => handleUserInput(inputText)}>
                                {isWaitingResponse ? <>  <Loader type="circular" size="small" /></> :
                                    <Icon tokens={sendSmallTokens}></Icon>}
                            </Button>
                        </Row>
                        <Row>
                            <Text type="b100" color='secondary'> {inputText.length}/{maxChars - inputText.length}</Text>
                        </Row>
                    </form>
                </Row>
                <Row alignmentHorizontal="center" spacing="200">
                    <Text alignment="center" color="secondary" type="b100" >AskGenie can make mistakes. Check important info. </Text>
                </Row>
            </div >
        </React.Fragment >
    );
};

export default AskGenieComponent;