
import { customHeaders } from "src/helpers/types/askGenie.types";
import { METHOD_POST, apisURl } from "../../common/Constants";

import katalLoggerUtil from "../LoggerUtils";
import { isDevDomain } from "../utils";

// Will Handle Generic Api calls + Polevault Generic
export const historyApi = async (payload: any, idToken: any) => {

    var katalLogger = katalLoggerUtil(window.location.href);

    const fullUrl = apisURl['backendUrl'] + "/conversation/history"

    try {
        var header: customHeaders = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,

        }
        if (isDevDomain()) {
            header = {
                ...header,
                devdomain: 'askgenie-integ.transportation.amazon.dev'
            }
        }
        const response = await fetch(fullUrl, {
            method: METHOD_POST,

            headers: header,
            body: JSON.stringify(payload)
        });
        const result = await response.json();
        return result
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}