import React, { useState } from "react"
import Masthead, {
    MastheadLink,
} from "@amzn/meridian/masthead"
import Icon from "@amzn/meridian/icon"
import Link from "@amzn/meridian/link"
import cogTokens from "@amzn/meridian-tokens/base/icon/cog"
import phoneTokens from "@amzn/meridian-tokens/base/icon/phone"
import helpTokens from "@amzn/meridian-tokens/base/icon/help"
import clipboard from "@amzn/meridian-tokens/base/icon/clipboard"

import Theme from "@amzn/meridian/theme"
import chatbotLogo from 'src/images/askgenie_logo.png';
import Column from "@amzn/meridian/column"
import Text from "@amzn/meridian/text"
import Box from "@amzn/meridian/box"
import "./app-footer.component.scss";

const Footer = () => {

    const [selected, setSelected] = useState("")
    const onClickLink = (id: React.SetStateAction<string>) => () => setSelected(id)

    return (
        <footer>
            <Theme  >
                <Masthead size="small" >
                    <MastheadLink selected={selected === "one"} onClick={onClickLink("https://w.amazon.com/bin/view/TransportationFrontline/AskGenie")}>
                        <img src={chatbotLogo} alt="..." className="footer-logo" /><Link type="secondary" href="https://w.amazon.com/bin/view/TransportationFrontline/AskGenie" target="_blank">AskGenie </Link>
                    </MastheadLink>
                    <MastheadLink selected={selected === "two"} onClick={onClickLink("https://w.amazon.com/bin/view/TransportationFrontline/AskGenie/Onboarding")}>
                        <Icon tokens={clipboard} />< Link type="secondary" href="https://w.amazon.com/bin/view/TransportationFrontline/AskGenie/Onboarding" target="_blank" > Onboarding</Link>
                    </MastheadLink>

                    <MastheadLink selected={selected === "three"} onClick={onClickLink("https://w.amazon.com/bin/view/TransportationFrontline/AskGenie#HContactUs")}>
                        <Icon tokens={phoneTokens} /><Link type="secondary" href="https://w.amazon.com/bin/view/TransportationFrontline/AskGenie#HContactUs" target="_blank">Contact Us</Link>
                    </MastheadLink>
                </Masthead >

            </Theme >
        </footer>
    )
}
export default Footer

