export const toQueryString = (params: Record<string, any>): string => {
  const urlParams: string = new URLSearchParams(params).toString();
  return urlParams;
};

export const getItemFromCache = (key: string): any => {
  const cachedValue = localStorage.getItem(key);
  return cachedValue === null ? null : JSON.parse(cachedValue);
};

export const setItemInCache = (key: string, value: any): void => {
  const objectToCache = value;
  localStorage.setItem(key, JSON.stringify(objectToCache));
};

export const isNotEmpty = (obj: any) => {
  return Object.keys(obj).some((key) => obj[key] !== "");
};

export const isDevDomain = () => {
  const hostname = window.location.hostname;
  if (hostname.includes("aka.corp.amazon.com")) {
    return true;
  }
  return false;
};
