export const footerLinks = {
  Items: [
    {
      visibleText: "About Us",
      value: "https://w.amazon.com/bin/view/TransportationFrontline/AskGenie",
    },
  ],
};

export const partnerLinks = {
  Items: [
    {
      visibleText: "PoleVault",
      value: "https://polevault-integ.ats.amazon.dev/",
    },
  ],
};
