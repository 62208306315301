import initialMetricsPublisher from "src/metrics";
import { METHOD_POST, apisURl } from "../../common/Constants";
import katalLoggerUtil, { katalLoggerError, katalLoggerInfo } from "../LoggerUtils";
// import { getSignedRequest } from "../auth/IAMAuthUtils";
import axios from "axios";
import { isDevDomain } from "../utils";
import { customHeaders } from "src/helpers/types/askGenie.types";


export const genericConversationAPI = async (payload: any, idToken: any) => {

  var katalLogger = katalLoggerUtil(window.location.href);
  const url = apisURl['backendUrl'] + "/conversation/generic"
  try {
    var header: customHeaders = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${idToken}`,

    }
    if (isDevDomain()) {
      header = {
        ...header,
        devdomain: 'askgenie-integ.transportation.amazon.dev'
      }
    }
    const response = await fetch(url, {
      method: METHOD_POST,

      headers: header,
      body: JSON.stringify(payload)
    });
    const result = await response.json();
    return result
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}