import React, { useState, useRef } from "react";
import { useAuthContext } from "src/auth/authenticationContext";
import Button from "@amzn/meridian/button";
import "./app-header.component.scss";

export const UserOptions = () => {
    var alias: string = useAuthContext().userAlias;
    const getImageUrl = () => `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${alias}&type=badge&dummy.png`;
    const [open, setOpen] = useState(false)
    const onClickButton = () => setOpen(true)
    const buttonRef = useRef()
    return (
        <React.Fragment>

            <Button
                onMouseEnter={onClickButton}
                ref={buttonRef}
                onClick={() => {
                    window.open(`https://phonetool.amazon.com/users/${alias}`, "_blank");
                }}
                type="link"
                size="medium"
                aria-expanded="false"
            >
                <div className="user-section">
                    Welcome, {alias}
                    <img src={getImageUrl()} alt="..." className="user-profile-icon" />
                </div>
            </Button>
        </React.Fragment>
    )
}