import React, { memo } from "react"
import { Route, Routes } from "react-router-dom"
import HomePageComponent from "src/components/askGenie/homepageComponent"



const HomeContentComponent = () => {

    return (
        <Routes>
            <Route path="/" element={<HomePageComponent />} />
        </Routes>
    )
}
export default memo(HomeContentComponent)