import KatalLogger from "@katal/logger";
import { DEFAULT_MAX_LOG_LINE_SIZE } from "../common/Constants";

/*
  This method calls publishes the info level logs to cloudwatch using katalLogger
  Restricitng the message size to 10000 chars as after that katal logger gets stuck and can only publish the logs if the message is
  only little above 10000 chars, if not the entire message gets stuck in pending.
*/
export const katalLoggerInfo = (katalLogger: any, info: string, data: any) => {
    var stringifiedData = JSON.stringify(data);
    var truncatedData =
        stringifiedData.length > DEFAULT_MAX_LOG_LINE_SIZE
            ? stringifiedData.substring(0, DEFAULT_MAX_LOG_LINE_SIZE)
            : stringifiedData;
    katalLogger.info(`${info}: ${truncatedData}`);
};

/*
  This method calls publishes the Error level logs to cloudwatch using katalLogger
  Restricitng the message size to 10000 chars as after that katal logger gets stuck and can only publish the logs if the message is
  only little above 10000 chars, if not the entire message gets stuck in pending.
*/
export const katalLoggerError = (katalLogger: any, info: string, data: any) => {
    var stringifiedData = JSON.stringify(data);
    var truncatedData =
        stringifiedData.length > DEFAULT_MAX_LOG_LINE_SIZE
            ? stringifiedData.substring(0, DEFAULT_MAX_LOG_LINE_SIZE)
            : stringifiedData;
    katalLogger.error(`${info}: ${truncatedData}`);
    console.error(info, JSON.stringify(data));
};

const katalLoggerUtil = (browserUrl: string) =>
    new KatalLogger({
        url: "{{LoggerUrl}}",
        maxLogLineSize: 20000,
        logToConsole: false,
        context: {
            path: browserUrl,
        },
    });

export default katalLoggerUtil;